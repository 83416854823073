import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui

import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import SBSLogo from '../assets/sbs_logo.png'
import zeejLogo from '../assets/zeej-logo-new.png';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export function LogoHome({ disabledLink = false, sx }) {
  // OR
  const logo = <Box component="img" src={zeejLogo} sx={{ width: 150, ...sx }} />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export default function Logo({ disabledLink = false, sx }) {
  // OR
  const logo = <Box component="img" src={zeejLogo} sx={{ width: 120, ...sx }} />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/category">{logo}</RouterLink>;
}
