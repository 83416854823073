import { func } from "prop-types";
import { apiUrl } from "./api";


export function getAccessToken() {
    return sessionStorage.getItem('access_token', null);
}

export function isAuthenticated() {
    const access = sessionStorage.getItem('access_token', null);
    console.log(`access >> ${access}`);

    if (access != null) {
        return true
    }

    return false
}

export default isAuthenticated;

// export function saveUser(result) {
    
//     console.log('saveUser', result);

//     sessionStorage.setItem('refresh', result.refresh)
//     sessionStorage.setItem('access_token', result.access)
//     sessionStorage.setItem('accountId', result.account.id)
//     sessionStorage.setItem('first_name', result.account.first_name)
//     sessionStorage.setItem('last_name', result.account.last_name)
//     sessionStorage.setItem('mobile', result.account.mobile)
//     sessionStorage.setItem('email', result.account.email)
//     // sessionStorage.setItem('role', result.account.role)
//     // sessionStorage.setItem('username', result.account.username)
//     // sessionStorage.setItem('photo', result.account.photo)
//     // sessionStorage.setItem('is_active', result.account.is_active)
//     // sessionStorage.setItem('verified', result.account.verified)
//     // sessionStorage.setItem('email_verified', result.account.email_verified)
//     // sessionStorage.setItem('phone_verified', result.account.phone_verified)
// }
export function saveUser(response) {
    // Extract 'result' from the 'response' object
    const result = response.data;

    console.log('saveUser', result);

    // Ensure 'result' and 'result.account' are defined
    if (result && result.account) {
        sessionStorage.setItem('refresh', result.refresh);
        sessionStorage.setItem('access_token', result.access);
        sessionStorage.setItem('accountId', result.account.id);
        sessionStorage.setItem('first_name', result.account.first_name);
        sessionStorage.setItem('last_name', result.account.last_name);
        sessionStorage.setItem('mobile', result.account.mobile);
        sessionStorage.setItem('email', result.account.email);
        sessionStorage.setItem('company', result.account.company.name); // Store company if needed
        sessionStorage.setItem('is_active', result.account.is_active);
        sessionStorage.setItem('companyID', result.account.company.id)

        // Additional fields can be stored as needed
        // sessionStorage.setItem('role', result.account.role);
        // sessionStorage.setItem('username', result.account.username);
        // sessionStorage.setItem('photo', result.account.photo);
        // sessionStorage.setItem('verified', result.account.verified);
        // sessionStorage.setItem('email_verified', result.account.email_verified);
        // sessionStorage.setItem('phone_verified', result.account.phone_verified);
    } else {
        console.error('Account data is missing in the result:', result);
    }
}

export function updateUser(result) {
    sessionStorage.setItem('accountId', result.account.id)
    sessionStorage.setItem('first_name', result.account.first_name)
    sessionStorage.setItem('last_name', result.account.last_name)
    sessionStorage.setItem('mobile', result.account.mobile)
    sessionStorage.setItem('email', result.account.email)
    sessionStorage.setItem('role', result.account.role)
    sessionStorage.setItem('is_active', result.account.is_active)
    sessionStorage.setItem('verified', result.account.verified)
    sessionStorage.setItem('email_verified', result.account.email_verified)
    sessionStorage.setItem('phone_verified', result.account.phone_verified)
    sessionStorage.setItem('companyID', result.account.company.id)
    sessionStorage.setItem('name', result.account.company.name)

}

export function logout() {
    sessionStorage.removeItem('refresh')
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('first_name')
    sessionStorage.removeItem('last_name')
    sessionStorage.removeItem('mobile')
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('accountId')
    sessionStorage.removeItem('email_verified')
    sessionStorage.removeItem('phone_verified')

    sessionStorage.clear();

    console.log(`clear storage:`)
}

export function getUser() {
    return {
        first_name: sessionStorage.getItem('first_name', null),
        last_name: sessionStorage.getItem('last_name', null),
        photo: sessionStorage.getItem('photo', null),
        email: sessionStorage.getItem('email', null),
        mobile: sessionStorage.getItem('mobile', null),
        role: sessionStorage.getItem('role', null),
        address: sessionStorage.getItem('address', null),
        accountId: sessionStorage.getItem('accountId', null),
        email_verified: sessionStorage.getItem('email_verified', null),
        phone_verified: sessionStorage.getItem('phone_verified', null),
    }
}

export const accountId = () => {
    return sessionStorage.getItem('accountId', null)
}

export function getUserEmail() {
    return sessionStorage.getItem('email', null)
}


export const roleName = () => {
    return sessionStorage.getItem('role', null);
}

export function isAdmin() {
    return (sessionStorage.getItem('role', null) === 'A')
}

export function isStaff() {
    return (sessionStorage.getItem('role', null) === 'ST')
}

export function getUserName() {
    return `${sessionStorage.getItem('first_name', '')} ${sessionStorage.getItem('last_name', '')}`
}

export function getUserId() {
    return sessionStorage.getItem('email', '')
}

export function getProfilePhoto() {
    const photo = sessionStorage.getItem('photo', '')
    if(photo != null) {
        return `${apiUrl.account}${photo}`
    }
    return null
}

const roleIdName = (role) => {
    switch(role) {
        case 'A':
            return 'Agent'
        case 'U':
            return 'User'
        default:
            return 'Admin'
    }
}


export const roleId = () => {
    return sessionStorage.getItem('role', null);
}

export function getPhoto() {
    return sessionStorage.getItem('photo', null)
}
export function setProductCategory(category) {
    sessionStorage.setItem('ProductCategory', category);
}

export function getProductCategory() {
    return sessionStorage.getItem('ProductCategory');
}

export function getCompanyId() {
    return sessionStorage.getItem('companyID')
}
export function getCompanyName() {
    return sessionStorage.getItem('company');
}