import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Grid,
  Button,
  Container,
  CssBaseline,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import validator from 'validator';

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostFormData, apiPostSecure, apiCallPutWithForm, apiUrl } from '../../../../utils/api';

import ImagePreview, { ImagePreviewReset } from '../../../../components/imagePreview/ImagePreview';

const defY = 1;
const createPassword = () => {
  return Math.random().toString(36).slice(2, 7);
};

export const EditFAQs = (props) => {
  const { state, change, onSaveChange, onError } = props;
  const [updateApiCalling, setUpdateApiCalling] = useState(false);

  const handleClose = () => change(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (state) {
      setValue('question_en', state.question_en);
      setValue('answer_en', state.answer_en);
      setValue('question_ar', state.question_ar);
      setValue('answer_ar', state.answer_ar);
    } else {
      reset();
    }
  }, [state, setValue, reset]);

  const updateFAQData = (data) => {
    if (updateApiCalling) {
      return;
    }

    setUpdateApiCalling(true);

    const faqData = {
      id: state.id,
      question_en: data.question_en,
      answer_en: data.answer_en,
      question_ar: data.question_ar,
      answer_ar: data.answer_ar,
    };

    console.log('data', faqData);

    onSaveChange(0, 'Saving FAQ...');
    apiCallPostFormData(
      apiUrl.FAQs,
      faqData,
      (response) => {
        setUpdateApiCalling(false);
        reset();
        onSaveChange(1, response.data);
        console.log('success', response);
        handleClose();
      },
      (errorMsg) => {
        setUpdateApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
        console.log('error', errorMsg);
      }
    );
  };

  return (
    <Dialog open={state != null} onClose={handleClose}>
      <DialogTitle>Edit FAQs</DialogTitle>
      <DialogContent>
        <form id="update-form" onSubmit={handleSubmit(updateFAQData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Question English*"
                      autoFocus
                      {...register('question_en', { required: 'Enter English Question' })}
                      error={!!errors.question_en}
                      helperText={errors.question_en ? errors.question_en.message : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Answer English*"
                      multiline
                      rows={4}
                      {...register('answer_en', { required: 'Enter English Answer' })}
                      error={!!errors.answer_en}
                      helperText={errors.answer_en ? errors.answer_en.message : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Question Arabic*"
                      {...register('question_ar', { required: 'Enter Arabic Question' })}
                      error={!!errors.question_ar}
                      helperText={errors.question_ar ? errors.question_ar.message : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Answer Arabic*"
                      multiline
                      rows={4}
                      {...register('answer_ar', { required: 'Enter Arabic Answer' })}
                      error={!!errors.answer_ar}
                      helperText={errors.answer_ar ? errors.answer_ar.message : ''}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="update-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddFAQs = (props) => {
  const { show, change, roleName, onSaveChange, onError } = props;

  const [addApiCalling, setAddApiCalling] = React.useState(false);

  const handleClose = () => {
    reset({
      question_en: '',
      answer_en: '',
      question_ar: '',
      answer_ar: '',
    });
    change(false);
  };

  console.log('AddFAQs', show);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const saveData = (data) => {
    setTimeout(() => {
      clearErrors();
    }, 3000);

    if (addApiCalling) {
      return;
    }
    setAddApiCalling(true);

    const faqData = {
      question_en: data.question_en,
      answer_en: data.answer_en,
      question_ar: data.question_ar,
      answer_ar: data.answer_ar,
    };

    console.log(' data ', faqData);

    onSaveChange(0, 'Saving FAQ...');
    apiPostSecure(
      apiUrl.FAQs,
      faqData,
      (response) => {
        setAddApiCalling(false);
        reset({
          question_en: '',
          answer_en: '',
          question_ar: '',
          answer_ar: '',
        });
        onSaveChange(1, response.data);
        console.log('success', response);
        handleClose();
      },
      (errorMsg) => {
        setAddApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
        console.log('error', errorMsg);
      }
    );
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Add FAQs </DialogTitle>
      <DialogContent>
        <form id="save-form" onSubmit={handleSubmit(saveData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Question English*"
                      autoFocus
                      {...register('question_en', { required: 'Enter English Question' })}
                      error={!!errors.question_en}
                      helperText={errors.question_en ? errors.question_en.message : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Answer English*"
                      multiline
                      rows={4}
                      {...register('answer_en', { required: 'Enter English Answer' })}
                      error={!!errors.answer_en}
                      helperText={errors.answer_en ? errors.answer_en.message : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Question Arabic*"
                      {...register('question_ar', { required: 'Enter Arabic Question' })}
                      error={!!errors.question_ar}
                      helperText={errors.question_ar ? errors.question_ar.message : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Answer Arabic*"
                      multiline
                      rows={4}
                      {...register('answer_ar', { required: 'Enter Arabic Answer' })}
                      error={!!errors.answer_ar}
                      helperText={errors.answer_ar ? errors.answer_ar.message : ''}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>

        <Button variant="contained" type="submit" form="save-form">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFAQs;
