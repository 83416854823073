import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Button, Stack, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { apiUrl, apiCallSecureGet } from '../../../utils/api';
import Page from '../../../components/Page';
import { AlertDialog } from '../../../utils/diloag';
import { deletePropertyById } from './components/customerFeedbackService';
import { showError, showSuccess } from '../../../utils/toast';
import DefImage from '../../../assets/logo.jpeg';
import MyPropertyCard from './components/MyCustomerCard';

const CustomerFeedbackPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [CustomerFeedback, setCustomerFeedback] = React.useState([]);

  const [loadData, setLoadData] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState('');
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [loadUsers, setLoadUsers] = useState(true);
  const deleteProperty = React.useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loadUsers) {
      return;
    }
    setLoadUsers(false);
    apiCallSecureGet(
      apiUrl.contact,
      (response) => {
        console.log('response setCustomerFeedback', response.data);
        setCustomerFeedback(response.data);
      },
      (error) => {
        showError(error);
        console.log('api call fail', error);
      }
    );
  }, [loadUsers]);

  const onSuccess = (msg) => {
    showSuccess(msg);
    setLoadData(true);
  };

  const onError = (msg) => {
    showError(msg);
  };

  const onReloadData = () => {
    setLoadData(true);
  };

  const onDelete = (CustomerFeedback) => {
    deleteProperty.current = CustomerFeedback;
    setDeleteMessage(`Are you sure you want to delete "${CustomerFeedback.name} "?`);
  };

  const onCancelDelete = () => {
    setDeleteMessage('');
  };

  const onDeleteClick = () => {
    setDeleteMessage('');
    if (deleteProperty.current) {
      deletePropertyById(
        deleteProperty.current?.id,
        (response) => {
          showSuccess(response.data);
          setLoadUsers(true);
        },
        (error) => {
          showError(error);
        }
      );
    }
  };

  const onNewAccount = (status, message) => {
    if (status === 1) {
      setLoadingTitle(null);
      showSuccess(message);
      setLoadData(true);
      setLoadUsers(true);
    } else if (status === 2) {
      setLoadingTitle(null);
      showError(message);
    } else {
      setLoadingTitle(message);
    }
  };

  return (
    <Page title="CustomerFeedback">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Customer Feedback
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {CustomerFeedback.map((feedback) => (
          <Grid item xs={12} md={6} lg={4} key={feedback.id}>
            <MyPropertyCard property={feedback} onDelete={onDelete} />
          </Grid>
        ))}
      </Grid>
      <AlertDialog
        showCancel={true}
        title={`Delete Project!`}
        message={deleteMessage}
        onOkey={onDeleteClick}
        onCancel={onCancelDelete}
      />
    </Page>
  );
};

export default CustomerFeedbackPage;
