import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Stack, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { apiUrl, apiCallSecureGet } from '../../../utils/api';
import Page from '../../../components/Page';
import { AlertDialog } from '../../../utils/diloag';
import { deletePropertyById } from './components/projectService';
import { showError, showSuccess } from '../../../utils/toast';
import AddProjectModel, { EditProjectModel } from './components/Modals';
import DefImage from '../../../assets/logo.jpeg';
import ProjectCard from './components/MyPropertyCard';

const ProjectPAge = () => {
  const [product, setProduct] = React.useState([]);
  const [loadData, setLoadData] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState('');
  const [showAddProject, setShowAddProject] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [loadProject, setLoadProject] = useState(true);
  const [editProject, setEditProject] = useState(null);
  const deleteProperty = React.useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loadProject) {
      return;
    }
    setLoadProject(false);
    apiCallSecureGet(
      apiUrl.project,
      (response) => {
        console.log('response', response.data);
        setProduct(response.data);
      },
      (error) => {
        showError(error);
        console.log('api call fail', error);
      }
    );
  }, [loadProject]);

  const onSuccess = (msg) => {
    showSuccess(msg);
    setLoadData(true);
  };

  const onError = (msg) => {
    showError(msg);
  };

  const onEdit = (project) => {
    console.log('onEdit', project);
    setEditProject(project);
  };

  const onDelete = (project) => {
    deleteProperty.current = project;
    setDeleteMessage(`Are you sure you want to delete "${project.name_en} "?`);
  };

  const onCancelDelete = () => {
    setDeleteMessage('');
  };

  const onDeleteClick = () => {
    setDeleteMessage('');
    if (deleteProperty.current) {
      deletePropertyById(
        deleteProperty.current?.id,
        (response) => {
          showSuccess(response.data);
          setLoadData(true);
          setLoadProject(true);
        },
        (error) => {
          showError(error);
        }
      );
    }
  };

  const onNewProject = (status, message) => {
    if (status === 1) {
      setLoadingTitle(null);
      setEditProject(null);
      console.log('Success message:', message);
      showSuccess(message);
      setLoadData(true);
      setLoadProject(true);
    } else if (status === 2) {
      setLoadingTitle(null);
      showError(message);
    } else {
      setLoadingTitle(message);
    }
  };

  return (
    <Page title="Project">
      <AddProjectModel
        show={showAddProject}
        change={setShowAddProject}
        onSaveChange={onNewProject}
        onError={showError}
      />

      <EditProjectModel
        state={editProject}
        change={setEditProject}
        onSaveChange={onNewProject}
        onError={showError}
        roleName={'A'}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Projects
        </Typography>
        <Button
          sx={{ textTransform: 'none', m: 2 }}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setShowAddProject(true)}
        >
          Add Project
        </Button>
      </Stack>
      <Grid container spacing={2}>
        {product.map((project) => (
          <Grid item xs={12} md={6} lg={4}>
            <ProjectCard
              name_en={project.name_en}
              name_ar={project.name_ar}
              photo_sm={project.photo_sm}
              photo_lg={project.photo_lg}
              onEdit={() => onEdit(project)}
              onDelete={() => onDelete(project)}
            />
          </Grid>
        ))}
      </Grid>
      <AlertDialog
        showCancel={true}
        title={`Delete Project!`}
        message={deleteMessage}
        onOkey={onDeleteClick}
        onCancel={onCancelDelete}
      />
    </Page>
  );
};

export default ProjectPAge;
