import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Page from '../../../components/Page';
import AddCategoryModel, { EditCategoryModel } from './components/Modals';
import { AlertDialog } from '../../../utils/diloag';

import {
  apiCallSecureGet,
  apiUrl,
} from '../../../utils/api';
import { showError, showSuccess } from '../../../utils/toast';
import CategoryCard from './components/CategoryCard';
import { deleteCategoryById } from './components/CategoryService';

const CategoryPage = () => {

  const [categoryList, setCategoryList] = React.useState([]);
  const [loadData, setLoadData] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState('');
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [loadCategory, setLoadCategory] = useState(true);
  const [editCategory, setEditCategory] = useState(null);
  const deleteProperty = React.useRef(null);

  useEffect(() => {
    if (!loadCategory) {
      return;
    }
    setLoadCategory(false);
    apiCallSecureGet(
      apiUrl.category,
      (response) => {
        console.log('response', response.data);
        setCategoryList(response.data);
      },
      (error) => {
        showError(error);
        console.log('api call fail', error);
      }
    );
  }, [loadCategory]);

  const onSuccess = (msg) => {
    showSuccess(msg);
    setLoadData(true);
  };

  const onEdit = (category) => {
    console.log('onEdit', category);
    setEditCategory(category);
  };

  const onDelete = (category) => {
    deleteProperty.current = category;
    setDeleteMessage(`Are you sure you want to delete "${category.name_en} "?`);
  };

  const onCancelDelete = () => {
    setDeleteMessage('');
  };

  const onDeleteClick = () => {
    setDeleteMessage('');
    if (deleteProperty.current) {
      deleteCategoryById(
        deleteProperty.current?.id,
        (response) => {
          onSuccess(response.data);
          setLoadData(true);
          setLoadCategory(true);
        },
        (error) => {
          showError(error);
        }
      );
    }
  };

  const onNewCategory = (status, message) => {
    if (status === 1) {
      setLoadingTitle(null);
      setEditCategory(null);
      console.log('Success message:', message);
      onSuccess(message);
      setLoadData(true);
      setLoadCategory(true);
    } else if (status === 2) {
      setLoadingTitle(null);
      showError(message);
    } else {
      setLoadingTitle(message);
    }
  };

  return (
    <Page title="Category">
      <AddCategoryModel
        show={showAddCategory}
        change={setShowAddCategory}
        onSaveChange={onNewCategory}
        onError={showError}
      />

      <EditCategoryModel
        state={editCategory}
        change={setEditCategory}
        onSaveChange={onNewCategory}
        onError={showError}
        roleName={'A'}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Category
        </Typography>
        <Button
          sx={{ textTransform: 'none', m: 2 }}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setShowAddCategory(true)}
        >
          Add Category
        </Button>
      </Stack>
      <Grid container spacing={2} sx={{ pr: 4 }}>
        {categoryList?.map((category) => (
          <Grid item xs={12} md={6} lg={4}>
            <CategoryCard
              name_en={category.name_en}
              name_ar={category.name_ar}
              photo_sm={category.photo}
              dec_en={category.description_en}
              dec_ar={category.description_ar}
              onEdit={() => onEdit(category)}
              onDelete={() => onDelete(category)}
            />
          </Grid>
        ))}
      </Grid>
      <AlertDialog
        showCancel={true}
        title={`Delete Category!`}
        message={deleteMessage}
        onOkey={onDeleteClick}
        onCancel={onCancelDelete}
      />
    </Page>
  );
};

export default CategoryPage;
