import * as React from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Grid,
  Button,
  Container,
  CssBaseline,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Stack,
} from '@mui/material';

import validator from 'validator';

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostFormData, apiCallPostWithForm, apiCallPutWithForm, apiUrl } from '../../../../utils/api';

import ImagePreview, { ImagePreviewReset } from '../../../../components/imagePreview/ImagePreview';

const defY = 1;
const createPassword = () => {
  return Math.random().toString(36).slice(2, 7);
};

export const EditProjectModel = (props) => {
  const { state, change, onSaveChange, onError } = props;
  const [photo, setPhoto] = React.useState(null);
  const [updateApiCalling, setUpdateApiCalling] = React.useState(false);
  const [photoSmall, setPhotoSmall] = React.useState(null);
  // const [photoLarge, setPhotoLarge] = React.useState(null);
  const [previewSmall, setPreviewSmall] = React.useState(null);
  // const [previewLarge, setPreviewLarge] = React.useState(null);

  const handleClose = () => change(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    if (state) {
      console.log('State received:', state);
      setValue('id', state.id);
      setValue('project_name_en', state.name_en || '');
      setValue('project_name_ar', state.name_ar || '');
      setValue('description_en', state.description_en || '');
      setValue('description_ar', state.description_ar || '');

      setPreviewSmall(state.photo ? `${apiUrl.media}${state.photo}` : null);
      console.log('small', previewSmall);
    }
  }, [state, setValue]);

  const updateProjectData = (data) => {
    if (updateApiCalling) return;

    setUpdateApiCalling(true);

    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name_en', data.project_name_en);
    formData.append('name_ar', data.project_name_ar);
    formData.append('description_ar', data.description_ar);
    formData.append('description_en', data.description_en);

    if (photoSmall) {
      formData.append('photo', photoSmall);
    } else {
      formData.append('photo', state.photo_sm);
    }

    console.log('Form Data:', Object.fromEntries(formData.entries()));
    onSaveChange(0, 'updating project...');
    apiCallPostFormData(
      apiUrl.homeSlider,
      formData,
      (response) => {
        console.log('response', response);
        setUpdateApiCalling(false);
        reset();
        setPhotoSmall(null);
        // setPhotoLarge(null)
        onSaveChange(1, response.data);
        // handleClose();
      },
      (errorMsg) => {
        console.log('error', errorMsg);
        setUpdateApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
      }
    );
  };
  const onImageSetSmall = (file) => {
    if (file.size > 300 * 1024) {
      onError('Small photo should not be more than 300 KB.');
      setPreviewSmall(null);
      setPhotoSmall(null);
      return;
    }
    setPhotoSmall(file);
    setPreviewSmall(URL.createObjectURL(file));
  };

  const onImageSetLarge = (file) => {
    console.log('Large Image File:', file);
  };

  return (
    <Dialog open={!!state} onClose={handleClose}>
      <DialogTitle>Edit Slider</DialogTitle>
      <DialogContent>
        <form id="update-form" onSubmit={handleSubmit(updateProjectData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <ImagePreviewReset
                    preview={previewSmall}
                    setPreview={setPreviewSmall}
                    onFileSelectSuccess={onImageSetSmall}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change  Image', upload: 'Upload  Image' }}
                  />
                </Box>
              </Stack>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Name (English) *"
                      autoFocus
                      {...register('project_name_en', { required: 'Enter Slider name in English' })}
                      defaultValue={state?.name_en || ''}
                      onChange={(e) => setValue('project_name_en', e.target.value)}
                    />
                    {errors.project_name_en && <p>{errors.project_name_en.message}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Name (Arabic) *"
                      autoFocus
                      {...register('project_name_ar', { required: 'Enter Slider name in Arabic' })}
                      defaultValue={state?.name_ar || ''}
                      onChange={(e) => setValue('project_name_ar', e.target.value)}
                    />
                    {errors.project_name_ar && <p>{errors.project_name_ar.message}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Description (English) *"
                      autoFocus
                      {...register('description_en', { required: 'Enter Project name in Arabic' })}
                      defaultValue={state?.name_ar || ''}
                      onChange={(e) => setValue('description_en', e.target.value)}
                    />
                    {errors.project_name_ar && <p>{errors.project_name_ar.message}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Description (Arabic) *"
                      autoFocus
                      {...register('description_ar', { required: 'Enter Project name in Arabic' })}
                      defaultValue={state?.name_ar || ''}
                      onChange={(e) => setValue('description_ar', e.target.value)}
                    />
                    {errors.description_ar && <p>{errors.description_ar.message}</p>}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="update-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddProjectModel = (props) => {
  const { show, change, onSaveChange, onError } = props;

  const [photoSmall, setPhotoSmall] = React.useState(null);
  const [previewSmall, setPreviewSmall] = React.useState(null);
  const [addApiCalling, setAddApiCalling] = React.useState(false);

  const handleClose = () => {
    reset();
    change(false);
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const saveData = (data) => {
    if (addApiCalling) {
      return;
    }
    setAddApiCalling(true);

    const formData = new FormData();
    formData.append('photo', photoSmall || '');
    formData.append('name_en', data.Slider_name_en || '');
    formData.append('name_ar', data.Slider_name_ar || '');
    formData.append('description_en', data.description_en || '');
    formData.append('description_ar', data.description_ar || '');

    console.log('Form Data:', Object.fromEntries(formData.entries()));

    onSaveChange(0, 'Saving Slider...');
    apiCallPostWithForm(
      apiUrl.homeSlider,
      formData,
      (response) => {
        setAddApiCalling(false);
        reset();
        setPhotoSmall(null);
        setPreviewSmall(null);
        onSaveChange(1, response.data);
        handleClose();
      },
      (errorMsg) => {
        setAddApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
        console.log('Slider', errorMsg || 'Error');
      }
    );
  };

  const onImageSet = (file, type) => {
    console.log('Setting image:', file.name, 'Type:', type);

    if (type === 'small') {
      if (file.size > 300 * 1024) {
        onError('Small photo should not be more than 300 KB.');
        setPreviewSmall(null);
        setPhotoSmall(null);
        return;
      }
      setPhotoSmall(file);
      setPreviewSmall(URL.createObjectURL(file));
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Add Slider</DialogTitle>
      <DialogContent>
        <form id="save-form" onSubmit={handleSubmit(saveData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Stack direction="row" spacing={2}>
                <Box>
                  <ImagePreviewReset
                    preview={previewSmall}
                    setPreview={setPreviewSmall}
                    setPhoto={setPhotoSmall}
                    onFileSelectSuccess={(file) => onImageSet(file, 'small')}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change  Image', upload: 'Upload  Image' }}
                  />
                </Box>
              </Stack>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Name (English) *"
                      autoFocus
                      {...register('Slider_name_en', { required: 'Enter Slider name in English' })}
                      error={!!errors.Slider_name_en}
                      helperText={errors.Slider_name_en?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Name (Arabic) *"
                      autoFocus
                      {...register('Slider_name_ar', { required: 'Enter Slider name in Arabic' })}
                      error={!!errors.Slider_name_ar}
                      helperText={errors.Slider_name_ar?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Description (English) *"
                      {...register('description_en', { required: 'Enter Slider Description in English' })}
                      error={!!errors.description_en}
                      helperText={errors.description_en?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slider Description (Arabic) *"
                      {...register('description_ar', { required: 'Enter Slider Description in Arabic' })}
                      error={!!errors.description_ar}
                      helperText={errors.description_ar?.message}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="save-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectModel;
