import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';

const LoadingModal = (props) => {

  const { title } = props;

  return (
    <>
      <Dialog
        open={title != null}
        fullWidth
        style={{ backgroundColor: 'transparent'}}
        maxWidth="xs"
        BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.5)' } }}
      >
        <div>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <CircularProgress />
              </Grid>   
            </Grid> 
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default LoadingModal;
