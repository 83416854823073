import { apiUrl, apiDeleteSecureWithoutURL } from '../../../../utils/api';

export async function deleteProductById(id, onResponse, onError) {
  const data = {
    id: id,
  };
  console.log('data', data);

  await apiDeleteSecureWithoutURL(
    apiUrl.product,
    data,
    (response) => {
      onResponse(response);
    },
    (error) => {
      console.log(error);
      onError(error);
    }
  );
}
