import * as React from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Grid,
  Button,
  Container,
  CssBaseline,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Stack,
} from '@mui/material';

import validator from 'validator';

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostFormData, apiCallPostWithForm, apiCallPutWithForm, apiUrl } from '../../../../utils/api';

import ImagePreview, { ImagePreviewReset } from '../../../../components/imagePreview/ImagePreview';

const defY = 1;
const createPassword = () => {
  return Math.random().toString(36).slice(2, 7);
};

export const EditProjectModel = (props) => {
  const { state, change, onSaveChange, onError } = props;
  const [photo, setPhoto] = React.useState(null);
  const [updateApiCalling, setUpdateApiCalling] = React.useState(false);
  const [photoSmall, setPhotoSmall] = React.useState(null);
  const [photoLarge, setPhotoLarge] = React.useState(null);
  const [previewSmall, setPreviewSmall] = React.useState(null);
  const [previewLarge, setPreviewLarge] = React.useState(null);

  const handleClose = () => change(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    if (state) {
      console.log('State received:', state);
      setValue('id', state.id);
      setValue('project_name_en', state.name_en || '');
      setValue('project_name_ar', state.name_ar || '');
      setPreviewSmall(state.photo_sm ? `${apiUrl.media}${state.photo_sm}` : null);
      setPreviewLarge(state.photo_lg ? `${apiUrl.media}${state.photo_lg}` : null);
      console.log('smaill', previewSmall);
      console.log('large', previewLarge);
    }
  }, [state, setValue]);

  const updateProjectData = (data) => {
    if (updateApiCalling) return;

    setUpdateApiCalling(true);

    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name_en', data.project_name_en);
    formData.append('name_ar', data.project_name_ar);
    if (photoSmall) {
      formData.append('photo_sm', photoSmall);
    } else {
      formData.append('photo_sm', state.photo_sm);
    }

    if (photoLarge) {
      formData.append('photo_lg', photoLarge);
    } else {
      formData.append('photo_lg', state.photo_lg);
    }

    console.log('Form Data:', Object.fromEntries(formData.entries()));
    onSaveChange(0, 'updating project...');
    apiCallPostFormData(
      apiUrl.project,
      formData,
      (response) => {
        console.log('response', response);
        setUpdateApiCalling(false);
        reset()
        setPhotoSmall(null)
        setPhotoLarge(null)
        onSaveChange(1, response.data);
        // handleClose();
      },
      (errorMsg) => {
        console.log('error', errorMsg);
        setUpdateApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
      }
    );
  };
  const onImageSetSmall = (file) => {
    if (file.size > 300 * 1024) {
      onError('Small photo should not be more than 300 KB.');
      setPreviewSmall(null);
      setPhotoSmall(null);
      return;
    }
    setPhotoSmall(file);
    setPreviewSmall(URL.createObjectURL(file));
  };

  const onImageSetLarge = (file) => {
    console.log('Large Image File:', file);
    setPhotoLarge(file);
    setPreviewLarge(URL.createObjectURL(file));
  };

  return (
    <Dialog open={!!state} onClose={handleClose}>
      <DialogTitle>Edit Project</DialogTitle>
      <DialogContent>
        <form id="update-form" onSubmit={handleSubmit(updateProjectData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <ImagePreviewReset
                    preview={previewSmall}
                    setPreview={setPreviewSmall}
                    onFileSelectSuccess={onImageSetSmall}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change Small Image', upload: 'Upload Small Image' }}
                  />
                </Box>
                <Box>
                  <ImagePreviewReset
                    preview={previewLarge}
                    setPreview={setPreviewLarge}
                    onFileSelectSuccess={onImageSetLarge}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change Large Image', upload: 'Upload Large Image' }}
                  />
                </Box>
              </Stack>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Project Name (English) *"
                      autoFocus
                      {...register('project_name_en', { required: 'Enter Project name in English' })}
                      defaultValue={state?.name_en || ''}
                      onChange={(e) => setValue('project_name_en', e.target.value)}
                    />
                    {errors.project_name_en && <p>{errors.project_name_en.message}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Project Name (Arabic) *"
                      autoFocus
                      {...register('project_name_ar', { required: 'Enter Project name in Arabic' })}
                      defaultValue={state?.name_ar || ''}
                      onChange={(e) => setValue('project_name_ar', e.target.value)}
                    />
                    {errors.project_name_ar && <p>{errors.project_name_ar.message}</p>}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="update-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddProjectModel = (props) => {
  const { show, change, onSaveChange, onError } = props;

  const [photoLarge, setPhotoLarge] = React.useState(null);
  const [photoSmall, setPhotoSmall] = React.useState(null);
  const [previewSmall, setPreviewSmall] = React.useState(null);
  const [previewLarge, setPreviewLarge] = React.useState(null);
  const [addApiCalling, setAddApiCalling] = React.useState(false);

  const handleClose = () => {
    reset();
    change(false);
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const saveData = (data) => {
    if (addApiCalling) {
      return;
    }
    setAddApiCalling(true);

    const formData = new FormData();
    formData.append('photo_sm', photoSmall);
    formData.append('photo_lg', photoLarge);

    formData.append('name_en', data.project_name_en);
    formData.append('name_ar', data.project_name_ar);
    console.log('Form Data:', Object.fromEntries(formData.entries()));

    onSaveChange(0, 'Saving Project...');
    apiCallPostWithForm(
      apiUrl.project,
      formData,
      (response) => {
        setAddApiCalling(false);
        reset();
        setPhotoLarge(null);
        setPhotoSmall(null);
        setPreviewSmall(null);
        setPreviewLarge(null);
        onSaveChange(1, response.data);
        handleClose();
      },
      (errorMsg) => {
        setAddApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
        console.log('project', errorMsg || 'Error');
      }
    );
  };

  const onImageSet = (file, type) => {
    console.log('Setting image:', file.name, 'Type:', type);

    if (type === 'small') {
      if (file.size > 300 * 1024) {
        onError('Small photo should not be more than 300 KB.');
        setPreviewSmall(null);
        setPhotoSmall(null);
        return;
      }
      setPhotoSmall(file);
      setPreviewSmall(URL.createObjectURL(file));
    } else if (type === 'large') {
      setPhotoLarge(file);
      setPreviewLarge(URL.createObjectURL(file));
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Add Project</DialogTitle>
      <DialogContent>
        <form id="save-form" onSubmit={handleSubmit(saveData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Stack direction="row" spacing={2}>
                <Box>
                  <ImagePreviewReset
                    preview={previewSmall}
                    setPreview={setPreviewSmall}
                    setPhoto={setPhotoSmall}
                    onFileSelectSuccess={(file) => onImageSet(file, 'small')}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change Small Image', upload: 'Upload Small Image' }}
                  />
                </Box>
                <ImagePreviewReset
                  preview={previewLarge}
                  setPreview={setPreviewLarge}
                  setPhoto={setPhotoLarge}
                  onFileSelectSuccess={(file) => onImageSet(file, 'large')}
                  onFileSelectError={({ error }) => onError(error)}
                  uploadText={{ change: 'Change Large Image', upload: 'Upload Large Image' }}
                />
              </Stack>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Project Name (English) *"
                      autoFocus
                      {...register('project_name_en', { required: 'Enter Project name in English' })}
                      error={!!errors.project_name_en}
                      helperText={errors.project_name_en?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Project Name (Arabic) *"
                      autoFocus
                      {...register('project_name_ar', { required: 'Enter Project name in Arabic' })}
                      error={!!errors.project_name_ar}
                      helperText={errors.project_name_ar?.message}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="save-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectModel;
