// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const getMenuOptions = (role) => {
  console.log('getMenuOptions', role);
  let options = [];
  // if (role === 'X') {
    options = [
      {
        title: 'Product category',
        path: '/category',
        icon: getIcon('bx:category'),
      },
      {
        title: 'Products List',
        path: '/product',
        icon: getIcon('mingcute:building-5-fill'),
      },
      {
        title: 'projects ',
        path: '/project',
        icon: getIcon('fa6-solid:folder'),
      },
      {
        title: 'HomeSlider',
        path: '/slider',
        icon: getIcon('mdi:view-carousel'),
      },
      {
        title: 'Customer Feedback',
        path: '/CustomerFeedback',
        icon: getIcon('mdi:users-group'),
      },
      {
        title: 'FAQs',
        path: '/FAQs',
        icon: getIcon('mdi:frequently-asked-questions'),
      },
    ];
  // } else if (role === 'A') {
  //   options = [
  //     {
  //       title: 'Dashboard',
  //       path: '/dashboard/app',
  //       icon: getIcon('eva:pie-chart-2-fill'),
  //     },
  //     {
  //       title: 'Products',
  //       path: '/dashboard/property/search',
  //       icon: getIcon('mingcute:building-5-fill'),
  //     },

  //     {
  //       title: 'Users',
  //       path: '/users',
  //       icon: getIcon('mdi:users-group'),
  //     },
  //   ];
  // }

  console.log('options', options);
  return options;
};

export const getSettings = (role) => {
  if (role === 'A') {
    return [
      {
        title: 'Account',
        path: '/account',
        icon: getIcon('material-symbols:admin-panel-settings-rounded'),
      },
    ];
  }

  return [
    {
      title: 'Account',
      path: '/account',
      icon: getIcon('material-symbols:admin-panel-settings-rounded'),
    },
  ];
};

export default getMenuOptions;
