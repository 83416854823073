import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import FAQs from './pages/main/faqs';
import Product from './pages/main/product';
import Category from './pages/main/category';
import CustomerFeedback from './pages/main/customerFeedback';
import AccountPage from './pages/main/account';
import BlankLayout from './layouts/BlankLayout';
import Project from './pages/main/projects';
import HomeSlider from './pages/main/homeSlider';

import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Activate from './pages/auth/Activate';
import NotFound from './pages/Page404';
import Register from './pages/auth/Register';

export default function Router() {

  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'FAQs', element: <FAQs /> },
        { path: 'property', 
          children: [
            { path: 'search', element: <Project /> },
          ]
        },
        { path: 'project', element: <Project /> },
        { path: 'product', element: <Product /> },
        { path: 'category', element: <Category /> },
        { path: 'CustomerFeedback', 
          children:[
            {path: '', element: <CustomerFeedback />},
          ] 
        },
        { path: 'account', element: <AccountPage /> },
        { path: 'slider', element: <HomeSlider /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'activate', element: <Activate /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ]);
  
}
