import { apiUrl,apiDeleteSecureWithoutURL } from "../../../../utils/api";

export async function deleteCategoryById(id, onResponse, onError) {
    const data = {
      id: id,
    };
    console.log('id', data);
  
    await apiDeleteSecureWithoutURL(
      apiUrl.category,
      data,
      (response) => {
        onResponse(response);
      },
      (error) => {
        console.log(error);
        onError(error);
      }
    );
  }