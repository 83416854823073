import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Stack, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getProductCategory, setProductCategory } from '../../../utils/session';
import { apiUrl, apiCallSecureGet } from '../../../utils/api';
import Page from '../../../components/Page';
import { AlertDialog } from '../../../utils/diloag';
import { showError, showSuccess } from '../../../utils/toast';
import AddProductModel, { EditProductModel } from './components/Modals';
import DefImage from '../../../assets/logo.jpeg';
import ProductCard from './components/MyProductCard';
import { GetCategory } from './components/MyProductCard';
import { deleteProductById } from './components/productService';

const ProductPage = () => {
  const [product, setProduct] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [loadData, setLoadData] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState('');
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [loadProduct, setLoadProduct] = useState(true);
  const [editAccount, setEditAccount] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [categoryIds, setCategoryIds] = useState(getProductCategory());

  const deleteProduct = React.useRef(null);

  const navigate = useNavigate();

  const handleCategorySelect = (categoryIds) => {
    console.log('Selected Category IDs:', categoryIds);
    setSelectedCategories(categoryIds);
    setProductCategory(categoryIds);
    setLoadProduct(true);
  };

  useEffect(() => {
    if (!loadProduct) {
      return;
    }
    setLoadProduct(false);
    const savedCategory = getProductCategory();
    const categoryIds = savedCategory || selectedCategories;

    console.log('categoryIds >> ', categoryIds);

    if(!categoryIds) {
      return
    }
    const url = `${apiUrl.product}?category=${categoryIds}`;
    console.log('Fetching data from:', url);

    apiCallSecureGet(
      url,
      (response) => {
        console.log('response product', response.data);
        setProduct(response.data);
      },
      (error) => {
        showError(error);
        console.log('api call fail', error);
      }
    );
  }, [loadProduct, selectedCategories]);

  const onError = (msg) => {
    showError(msg);
  };

  const onEdit = (product) => {
    console.log('onEdit', product);
    setEditProduct(product);
  };

  const onDelete = (product) => {
    deleteProduct.current = product;
    setDeleteMessage(`Are you sure you want to delete "${product.name_en} "?`);
  };

  const onCancelDelete = () => {
    setDeleteMessage('');
  };

  const onDeleteClick = () => {
    setDeleteMessage('');
    if (deleteProduct.current) {
      deleteProductById(
        deleteProduct.current?.id,
        (response) => {
          showSuccess(response.data);
          setLoadData(true);
          setLoadProduct(true);
        },
        (error) => {
          showError(error);
        }
      );
    }
  };

  const onNewAccount = (status, message) => {
    if (status === 1) {
      setLoadingTitle(null);
      setEditProduct(null);
      console.log('Success message:', message);
      showSuccess(message);
      setLoadData(true);
      setLoadProduct(true);
    } else if (status === 2) {
      setLoadingTitle(null);
      showError(message);
    } else {
      setLoadingTitle(message);
    }
  };

  return (
    <Page title="product">
      <AddProductModel
        show={showAddAccount}
        change={setShowAddAccount}
        onSaveChange={onNewAccount}
        onError={showError}
      />

      <EditProductModel
        state={editProduct}
        change={setEditProduct}
        onSaveChange={onNewAccount}
        onError={showError}
        selectedCategories={selectedCategories}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Product
        </Typography>
        <Button
          sx={{ textTransform: 'none', m: 2 }}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setShowAddAccount(true)}
        >
          Add Product
        </Button>
      </Stack>
      <Grid ml={3} container spacing={2}>
        <Grid item xs={8} md={8} lg={8}>
          <GetCategory onCategorySelect={handleCategorySelect} showEmptyMessage={true} />
        </Grid>
      </Grid>

      <Grid container spacing={2} m={2}>
        {product.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" align="center">
              No data found
            </Typography>
          </Grid>
        ) : (
          product.map((product) => (
            <Grid item xs={12} md={6} lg={4} key={product.id}>
              <ProductCard
                name_en={product.name_en}
                name_ar={product.name_ar}
                photo_sm={product.photo_sm}
                photo_lg={product.photo_lg}
                code={product.code}
                onEdit={() => onEdit(product)}
                onDelete={() => onDelete(product)}
              />
            </Grid>
          ))
        )}
      </Grid>
      <AlertDialog
        showCancel={true}
        title={`Delete Product!`}
        message={deleteMessage}
        onOkey={onDeleteClick}
        onCancel={onCancelDelete}
      />
    </Page>
  );
};

export default ProductPage;
