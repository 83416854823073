import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Grid,
  Button,
  Container,
  CssBaseline,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Stack,
} from '@mui/material';

import validator from 'validator';

import { ErrorMessage } from '@hookform/error-message';
import {
  apiCallPostFormData,
  apiCallPostWithForm,
  apiCallPutWithForm,
  apiUrl,
  selectedCategories,
} from '../../../../utils/api';
import { setProductCategory, getProductCategory } from '../../../../utils/session';
import { GetCategory } from './MyProductCard';
import ImagePreview, { ImagePreviewReset } from '../../../../components/imagePreview/ImagePreview';

const defY = 1;
const createPassword = () => {
  return Math.random().toString(36).slice(2, 7);
};

export const EditProductModel = (props) => {
  const { state, change, onSaveChange, onError } = props;
  const [photo, setPhoto] = React.useState(null);
  const [updateApiCalling, setUpdateApiCalling] = React.useState(false);
  const [photoSmall, setPhotoSmall] = React.useState(null);
  const [photoLarge, setPhotoLarge] = React.useState(null);
  const [previewSmall, setPreviewSmall] = React.useState(null);
  const [previewLarge, setPreviewLarge] = React.useState(null);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [categoryError, setCategoryError] = useState('');

  const handleClose = () => change(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    if (state) {
      console.log('State received:', state);
      setValue('id', state.id);
      setValue('Product_name_en', state.name_en || '');
      setValue('product_name_ar', state.name_ar || '');
      setValue('product_name_ar', state.name_ar || '');
      setValue('Product_Code', state.code || '');

      setPreviewSmall(state.photo_sm ? `${apiUrl.media}${state.photo_sm}` : null);
      setPreviewLarge(state.photo_lg ? `${apiUrl.media}${state.photo_lg}` : null);

      if (state.categories) {
        setSelectedCategories(state.categories);
      }
      console.log('large', previewLarge);
    }
  }, [state, setValue]);

  const updateProjectData = (data) => {
    console.log('api calling');
    setCategoryError('');
    if (updateApiCalling) return;

    setUpdateApiCalling(true);
    const savedCategories = getProductCategory();

    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name_en', data.Product_name_en);
    formData.append('name_ar', data.product_name_ar);
    formData.append('code', data.Product_Code);
    formData.append('category', savedCategories);

    if (photoSmall) {
      formData.append('photo_sm', photoSmall);
    }

    if (photoLarge) {
      formData.append('photo_lg', photoLarge);
    }
    console.log('form data');
    console.log('Form Data:', Object.fromEntries(formData.entries()));
    onSaveChange(0, 'updating Product...');
    apiCallPostFormData(
      apiUrl.product,
      formData,
      (response) => {
        console.log('response update product', response);
        setUpdateApiCalling(false);
        setPhotoSmall(null);
        setPhotoLarge(null);
        onSaveChange(1, response.data);
        setProductCategory(selectedCategories);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (errorMsg) => {
        console.log('error', errorMsg);
        setUpdateApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
      }
    );
  };
  const onImageSetSmall = (file) => {
    if (file.size > 300 * 1024) {
      onError('Small photo should not be more than 300 KB.');
      setPreviewSmall(null);
      setPhotoSmall(null);
      return;
    }
    setPhotoSmall(file);
    setPreviewSmall(URL.createObjectURL(file));
  };

  const onImageSetLarge = (file) => {
    console.log('Large Image File:', file);
    setPhotoLarge(file);
    setPreviewLarge(URL.createObjectURL(file));
  };

  const handleCategorySelect = (categoryIds) => {
    console.log('Selected Category IDs:', categoryIds);

    const savedCategories = getProductCategory();
    if (savedCategories) {
      setSelectedCategories(savedCategories);
    } else {
      setSelectedCategories(categoryIds);
      setProductCategory(categoryIds);
    }
  };

  return (
    <Dialog open={!!state} onClose={handleClose}>
      <DialogTitle>Edit Product</DialogTitle>
      <DialogContent>
        <form id="update-form" onSubmit={handleSubmit(updateProjectData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <ImagePreviewReset
                    preview={previewSmall}
                    setPreview={setPreviewSmall}
                    onFileSelectSuccess={onImageSetSmall}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change Small Image', upload: 'Upload Small Image' }}
                  />
                </Box>
                <Box>
                  <ImagePreviewReset
                    preview={previewLarge}
                    setPreview={setPreviewLarge}
                    onFileSelectSuccess={onImageSetLarge}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change Large Image', upload: 'Upload Large Image' }}
                  />
                </Box>
              </Stack>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Product Name (English) *"
                      autoFocus
                      {...register('Product_name_en', { required: 'Enter Product name in English' })}
                      defaultValue={state?.name_en || ''}
                      error={!!errors.Product_name_en}
                      helperText={errors.Product_name_en?.message}
                      onChange={(e) => setValue('Product_name_en', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Product Name (Arabic) *"
                      autoFocus
                      {...register('product_name_ar', { required: 'Enter Product name in Arabic' })}
                      defaultValue={state?.name_ar || ''}
                      error={!!errors.product_name_ar}
                      helperText={errors.product_name_ar?.message}
                      onChange={(e) => setValue('product_name_ar', e.target.value)}
                    />
                    {errors.product_name_ar && <p>{errors.product_name_ar.message}</p>}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Product Code *"
                      autoFocus
                      {...register('Product_Code', { required: 'Enter Product code' })}
                      defaultValue={state?.code || ''}
                      error={!!errors.Product_Code}
                      helperText={errors.Product_Code?.message}
                      onChange={(e) => setValue('Product_Code', e.target.value)}
                    />
                    {errors.Product_Code && <p style={{ color: 'red' }}>{errors.Product_Code.message}</p>}
                  </Grid>

                  <Grid item xs={12}>
                    <GetCategory
                      onCategorySelect={handleCategorySelect}
                      showEmptyMessage={false}
                      selectedCategories={selectedCategories}
                    />
                    {categoryError && <p style={{ color: 'red' }}>{categoryError}</p>}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="update-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddProductModel = (props) => {
  const { show, change, onSaveChange, onError } = props;

  const [photoLarge, setPhotoLarge] = React.useState(null);
  const [photoSmall, setPhotoSmall] = React.useState(null);
  const [previewSmall, setPreviewSmall] = React.useState(null);
  const [previewLarge, setPreviewLarge] = React.useState(null);
  const [addApiCalling, setAddApiCalling] = React.useState(false);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const handleClose = () => {
    setPreviewLarge(null)
    setPreviewSmall(null)
    reset();
    change(false);
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const saveData = (data) => {
    if (addApiCalling) {
      return;
    }
    setAddApiCalling(true);
    const savedCategory = getProductCategory()
    const formData = new FormData();
    formData.append('photo_sm', photoSmall);
    formData.append('photo_lg', photoLarge);
    formData.append('name_en', data.Product_name_en);
    formData.append('name_ar', data.product_name_ar);
    formData.append('code', data.Product_Code);

    formData.append('category', savedCategory);

    console.log('Form Data:', Object.fromEntries(formData.entries()));

    onSaveChange(0, 'Saving Project...');
    apiCallPostWithForm(
      apiUrl.product,
      formData,
      (response) => {
        setAddApiCalling(false);
        onSaveChange(1, response.data);
        handleClose();
      
      },
      (errorMsg) => {
        setAddApiCalling(false);
        onSaveChange(2, errorMsg || 'Error');
        console.log('Product', errorMsg || 'Error');
      }
    );
  };

 
  const onImageSet = (file, type) => {
    console.log('Setting image:', file.name, 'Type:', type);

    if (type === 'small') {
      if (file.size > 300 * 1024) {
        onError('Small photo should not be more than 300 KB.');
        setPreviewSmall(null);
        setPhotoSmall(null);
        return;
      }
      setPhotoSmall(file);
      setPreviewSmall(URL.createObjectURL(file));
    } else if (type === 'large') {
      setPhotoLarge(file);
      setPreviewLarge(URL.createObjectURL(file));
    }
  };

  const handleCategorySelect = (categoryIds) => {
    console.log('Selected Category IDs:', categoryIds);
    setSelectedCategories(categoryIds);
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Add Product</DialogTitle>
      <DialogContent>
        <form id="save-form" onSubmit={handleSubmit(saveData)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Stack direction="row" spacing={2}>
                <Box>
                  <ImagePreviewReset
                    preview={previewSmall}
                    setPreview={setPreviewSmall}
                    setPhoto={setPhotoSmall}
                    onFileSelectSuccess={(file) => onImageSet(file, 'small')}
                    onFileSelectError={({ error }) => onError(error)}
                    uploadText={{ change: 'Change Small Image', upload: 'Upload Small Image' }}
                  />
                </Box>
                <ImagePreviewReset
                  preview={previewLarge}
                  setPreview={setPreviewLarge}
                  setPhoto={setPhotoLarge}
                  onFileSelectSuccess={(file) => onImageSet(file, 'large')}
                  onFileSelectError={({ error }) => onError(error)}
                  uploadText={{ change: 'Change Large Image', upload: 'Upload Large Image' }}
                />
              </Stack>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Product Name (English) *"
                      autoFocus
                      {...register('Product_name_en', { required: 'Enter Product name in English' })}
                      error={!!errors.Product_name_en}
                      helperText={errors.Product_name_en?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Product Name (Arabic) *"
                      autoFocus
                      {...register('product_name_ar', { required: 'Enter Product name in Arabic' })}
                      error={!!errors.product_name_ar}
                      helperText={errors.product_name_ar?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Product Code *"
                      autoFocus
                      {...register('Product_Code', { required: 'Enter Product Code ' })}
                      error={!!errors.product_name_ar}
                      helperText={errors.product_name_ar?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GetCategory onCategorySelect={handleCategorySelect} showEmptyMessage={false} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="save-form">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductModel;
