import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CardContent, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { dateToShowDateFormat, formatNumber } from '../../../../utils/ext';
import DefImage from '../../../../assets/town.png';
import { apiUrl } from '../../../../utils/api';
import { getTextOrDefault } from '../../../../utils/ext';

const CardInfo = ({ list }) => {
  return (
    <CardContent>
      {list.map((item, index) => (
        <Box key={index}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {item.title}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {getTextOrDefault(item.text)}
          </Typography>
        </Box>
      ))}
    </CardContent>
  );
};

const PersonalInfo = ({ property }) => {
  const list = [
    {
      title: 'Name',
      text: property.name,
    },
    {
      title: 'Email',
      text: property.email,
    },
    {
      title: 'Contact',
      text: property.phone,
    },
    {
      title: 'Message',
      text: property.message,
    },
  ];

  return <CardInfo list={list} />;
};

const MyPropertyCard = ({ property, onUpdateStatus, onUpdatePublish, option, onDelete }) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleEdit = () => {
    handleDialogClose();
  };

  const handleDelete = () => {
    onDelete(property);
    handleDialogClose();
  };

  return (
    <Card variant="outlined" sx={{ width: 350, height: 'auto', m: 1, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton aria-label="more options" variant="plain" color="neutral" size="sm" onClick={handleDialogOpen}>
          <MoreVertIcon color="primary" />
        </IconButton>
      </Box>
      <Box>
        <PersonalInfo property={property} />
      </Box>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Options</DialogTitle>
        <DialogContent>
          <Button
            variant="outlined"
            size="small"
            sx={{ m: 1 }}
            color="error"
            startIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default MyPropertyCard;
