import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  Grid,
  Box,
  Tooltip,
  ListItemIcon,
  Avatar,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MUIDataTable from 'mui-datatables';
import LoadingModal from '../../../components/settings/LoadingModal';
import DialogModal from '../../../components/settings/DialogModal';

import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';

import AddFAQs, { EditFAQs } from './components/Modals';

import {
  apiCallSecureDelete,
  apiCallSecureGet,
  apiPostSecure,
  apiUrl,
  apiDeleteSecureWithoutURL,
} from '../../../utils/api';
import { stringAvatar } from '../../../utils/ext';
import { showError, showSuccess } from '../../../utils/toast';

const FAQs = () => {
  const [loadFAQ, setLoadUsers] = useState(true);
  const [showAddFAQ, setShowAddFAQ] = useState(false);
  const [faqs, setFAQS] = useState([]);
  const [editFAQ, setEditFAQ] = useState(null);
  const [deleteFAQ, setDeleteFAQ] = useState(null);
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loadFAQ) {
      return;
    }
    setLoadUsers(false);
    apiCallSecureGet(
      apiUrl.FAQs,
      (response) => {
        setFAQS(response.data);
        console.log('response', response.data);
      },
      (error) => {
        showError(error);
        console.log('api call fail', error);
      }
    );
  }, []);

  const showDetails = (id) => {
    navigate(`/dashboard/users/details?account_id=${id}`);
  };

  const showEditFAQ = (faq) => {
    setEditFAQ(faq);
  };

  const showDeleteFAQ = (faq) => {
    setDeleteFAQ(faq);
  };

  const onNewFAQ = (status, message) => {
    if (status === 1) {
      setLoadingTitle(null);
      setEditFAQ(null);
      showSuccess(message);
      setLoadUsers(true);
    } else if (status === 2) {
      setLoadingTitle(null);
      showError(message);
    } else {
      setLoadingTitle(message);
    }
  };

  useEffect(() => {
    if (!loadFAQ) return;
    setLoadUsers(false);
    setLoading(true);
    apiCallSecureGet(
      apiUrl.FAQs,
      (response) => {
        setFAQS(response.data);
        setLoading(false);
      },
      (error) => {
        showError(error);
        setLoading(false);
      }
    );
  }, [loadFAQ]);

  const deleteClickHandler = async (state) => {
    if (!state) return; 
    const data = {
      id: state.id,
    };
    console.log('data', data);
    apiDeleteSecureWithoutURL(
      apiUrl.FAQs,
      data,
      (response) => {
        setDeleteFAQ(null);
        showSuccess(response.data);
        setLoadUsers(true);
        console.log('Account', response);
      },
      (errorMsg) => {
        setDeleteFAQ(null);
        showError(errorMsg || 'Error');
        console.log('Account', errorMsg || 'Error');
      }
    );
  };

  const modalHandleClose = () => {
    setDeleteFAQ(null);
  };

 
  const handleButtonClick = (event, action, faq) => {
    event.stopPropagation();
    if (action === 'Edit') {
      setEditFAQ(faq);
    } else if (action === 'Delete') {
      setDeleteFAQ(faq);
    }
  };

  return (
    <Page title="FAQs">
      <Container>
        <LoadingModal title={loadingTitle} />
        <EditFAQs
          state={editFAQ}
          change={setEditFAQ}
          onSaveChange={onNewFAQ}
          onError={showError}
        />
        <AddFAQs
          show={showAddFAQ}
          change={setShowAddFAQ}
          onSaveChange={onNewFAQ}
          roleName={'A'}
          onError={showError}
        />
        <DialogModal
          state={deleteFAQ}
          title="Delete FAQ?"
          message={`Do you want to delete the question "${deleteFAQ?.question_en}"?`}
          handleclose={modalHandleClose}
          addclickhandler={() => deleteClickHandler(deleteFAQ)}
          buttonlabel="Yes, Delete"
        />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            FAQs
          </Typography>
          <Box>
            <Grid container justify="flex-end">
              <Button
                sx={{ ml: 2 }}
                onClick={() => setShowAddFAQ(true)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add FAQs
              </Button>
            </Grid>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} />
        <Card>
          <div>
            {faqs.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="h6" gutterBottom>
                        {faq.question_en}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {faq.question_ar}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box sx={{ mr: 5 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ m: 1 }}
                          color="error"
                          startIcon={<Delete />}
                          onClick={(event) => handleButtonClick(event, 'Delete', faq)}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ m: 1 }}
                          startIcon={<EditIcon />}
                          onClick={(event) => {
                            event.stopPropagation();
                            showEditFAQ(faq);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.answer_en}</Typography>
                  <Typography>{faq.answer_ar}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Card>
      </Container>
    </Page>
  );
};

export default FAQs;
