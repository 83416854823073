import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { apiUrl } from '../../../../utils/api';

export const PhotoView = ({ photo, width = '100%', height = 'auto' }) => {
  const imageUrl = `${apiUrl.media}${photo}`;
  console.log('image url', imageUrl);
  return <img src={imageUrl} srcSet={imageUrl} loading="lazy" alt="" style={{ width, height }} />;
};

const ProjectCard = ({ name_en, name_ar, photo_sm, photo_lg, onEdit, onDelete }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);

  const handleClickOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const handleDetailOpen = () => setOpenDetailDialog(true);
  const handleDetailClose = () => setOpenDetailDialog(false);

  const handleEdit = () => {
    onEdit();
    handleClose();
  };
  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <>
      <Card variant="outlined" sx={{ width: 350, height: 'auto', m: 2, p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton aria-label="more options" color="primary" size="small" onClick={handleClickOpen}>
            <MoreVert />
          </IconButton>
        </Box>

        <Box
          sx={{
            flexBasis: `200px`,
            borderRadius: 'sm',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 200,
          }}
        >
          <PhotoView photo={photo_sm} />
        </Box>
        <Box m={1}>
          <Typography variant="h6" fontSize="md" onClick={handleDetailOpen} sx={{ cursor: 'pointer' }}>
            {name_en}
          </Typography>
        </Box>
        <Box m={1} sx={{ textAlign: 'right' }}>
          <Typography variant="h6" fontSize="md">
            {name_ar}
          </Typography>
        </Box>
      </Card>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Options</DialogTitle>
        <DialogContent>
          <Button variant="outlined" size="small" sx={{ m: 1 }} startIcon={<Edit />} onClick={handleEdit}>
            Edit
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ m: 1 }}
            color="error"
            startIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDetailDialog} onClose={handleDetailClose} maxWidth="md" fullWidth>
        <DialogTitle>Project Details</DialogTitle>
        <DialogContent>
          <PhotoView photo={photo_sm} width="100%" height="550px" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectCard;
